import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ReactChild, ReactFragment, ReactPortal, useRef } from "react"
import {Image} from 'primereact/image';
import { Button } from "primereact/button";
import { InputMask } from 'primereact/inputmask';
import { useState } from "react";
import { classNames } from "primereact/utils";
import ApiServices from "../../service/apiServices";
import EncryptService from "../../service/encrypt.service";
import { Toast } from 'primereact/toast';

export const Contact = (props: { data: { address: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; phone: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; email: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; facebook: string | undefined; twitter: string | undefined; youtube: string | undefined } }) => {

  let emptyForm = {
    clientName: '',
    email: '',
    telephone: '',
    location: '',
    detail: '',
    message: ''
  };

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [contact, setContact] = useState<any>(emptyForm);
  const toast = useRef<Toast>(null);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [showResp, setShowResp] = useState<boolean>(false);

  //Funcion para editar los campos
  const onInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    let _contact = { ...contact };
    _contact[`${name}`] = val;
    setContact(_contact);

    //regex email: !/\S+@\S+\.\S+/.test(data.email)
}

const handleSubmit = async () => {
  setSubmitted(true);

  if (contact.clientName && contact.email && contact.telephone && contact.location && contact.detail && contact.message)
  {
    let palabra: any = {
      "code": 400,
      "name": contact.clientName,
      "mail": contact.email,
      "phone": contact.telephone,
      "location": contact.location,
      "detail": contact.detail,
      "message": contact.message
    }

    palabra = await EncryptService.encryptData(JSON.stringify(palabra));

    ApiServices.processRequestAdd(palabra)
    .then(async (res) => {
      setShowForm(false);
        setContact(emptyForm);
        setSubmitted(false)
        setShowResp(true);
        
        setTimeout( () => {
            setShowForm(true)
            setShowResp(false)
        }, 6000)
    })
    .catch((error) => {
        /* Toasty Error */
        toast.current?.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
    });
  }
}

  return (
    <div id='contact'>
      <Toast ref={toast} />
      <div className='container'>
        <div className='col-md-6'>
          <h2>Sucursales</h2>
          <div className="col-md-12 contact-item">
            <div className="col-md-6">
              <Image className="img-responsive" src="images/landing/CR-IZTAPALAPA.png" alt="ubicación Iztapalapa" preview width="230"/>
            </div>
            <div className="col-md-6 address">
              <p><b>Dirección</b></p>
              <p> Avenida del árbol 106, colonia el triángulo, C.P. 09769, alcaldía Iztapalapa, Ciudad de México. </p>
              <p><b>Teléfono</b></p>
              <p><a href="tel:+5554282595"> 55-54-28-25-95</a></p>
              <p><a href="tel:+5554286108"> 55-54-28-61-08</a></p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-6">
              <Image className="img-responsive" src="images/landing/CR-BORDO PONIENTE.png" alt="ubicación nezahualcoyotl" preview width="230"/>
            </div>
            <div className="col-md-6">
              <p><b>Dirección</b></p>
              <small> Zona VIII de bordo poniente S/N, colonia el sol, C.P. 57103, Municipio de Nezahualcóyotl, Estado de México. </small>
              <p><b>Teléfono</b></p>
              <p><a href="tel:+5554282595"> 55-54-28-25-95</a></p>
              <p><a href="tel:+5554282592"> 55-54-28-25-92</a></p>
              <p><a href="tel:+5554286109"> 55-54-28-61-09</a></p>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          {showForm &&
          <div className="col-md-12">
              <div className="col-md-12 contactDiv">
                <h2>Contacto</h2>
                <p>Envía tus comentarios ó dudas</p>
              </div>

            <div className="flex flex-column col-md-12">
              <div className="contact-item contactForm">
                <span className="p-float-label">
                  <InputText
                    className={classNames({'p-invalid' : submitted && !contact.clientName })} 
                    id="clientName" 
                    value={contact.clientName} 
                    onChange={(e) => onInputChange(e, 'clientName')}/>
                  <label htmlFor="clientName">Nombre del cliente</label>
                </span>
              </div>

              <div className="contact-item contactForm">
                <span className="p-float-label">
                  <InputText 
                    id="correo" 
                    type="email"
                    className={classNames({'p-invalid' : submitted && !contact.email })}
                    value={contact.email} 
                    onChange={(e) => onInputChange(e, 'email')}  />
                  <label htmlFor="correo">Correo</label>
                </span>
              </div>

              <div className="contact-item contactForm">
                <span className="p-float-label">
                  <InputMask 
                    id="phone" 
                    mask="(999) 999-9999"
                    className={classNames({'p-invalid' : submitted && !contact.telephone })}
                    value={contact.telephone}
                    onChange={(e) => onInputChange(e, 'telephone')}></InputMask>
                  <label htmlFor="tel">Teléfono</label>
                </span>
              </div>

              <div className="contact-item contactForm">
                <span className="p-float-label">
                  <InputText 
                    type="text" 
                    id="ubicacion"
                    className={classNames({'p-invalid' : submitted && !contact.location })}
                    value={contact.location}
                    onChange={(e) => onInputChange(e, 'location')} />
                  <label htmlFor="ubicacion">Ubicación otra</label>
                </span>
              </div>

              <div className="contact-item contactForm">
                <span className="p-float-label">
                  <InputText 
                    type="text" 
                    id="detalleObra"
                    className={classNames({'p-invalid' : submitted && !contact.detail })}
                    value={contact.detail}
                    onChange={(e) => onInputChange(e, 'detail')}  />
                  <label htmlFor="detalleObra">Detalle obra</label>
                </span>
              </div>

              <div className="contact-item contactForm">
                <span className="p-float-label">
                  <InputTextarea 
                    id="mensaje" 
                    rows={5} 
                    cols={30}
                    className={classNames({'p-invalid' : submitted && !contact.message })}
                    value={contact.message}
                    onChange={(e) => onInputChange(e, 'message')}  />
                  <label htmlFor="mensaje">Mensaje</label>
                </span>
              </div>

              <Button label="ENVIAR" className="btn-lg" onClick={handleSubmit} ></Button>
            </div>
          </div>
          }

          {showResp &&
            <div className="responseContainer">
                <h5>Mensaje enviado</h5>
                <h6>¡Gracias por contactarnos!</h6>
                <div className="iconContainer">
                    <div className="icon">
                    <svg width="88" height="90" viewBox="0 30 200 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 65.7503L8.16915 71.6712L54.616 105.343V157.243L95.0082 134.63L131.149 160.826L200 0.172852L0 65.7503ZM22.393 69.2505L172.975 19.8787L61.1755 97.372L22.393 69.2505ZM64.9228 139.663V112.812L85.8187 127.963L64.9228 139.663ZM126.756 144.916L67.4908 101.953L178.004 25.3468L126.756 144.916Z" fill="black"/>
                    </svg>
                    </div>
                </div>
                <h6>En breve nos comunicaremos contigo</h6>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
