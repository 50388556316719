import { url } from 'inspector';
import { ScrollPanel } from 'primereact/scrollpanel';

export const Products = (props: { data: any[] }) => {

  return (
    <div id='products'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>NUESTROS PRODUCTOS</h2>
        </div>
        <div className='row'>
          <div className="col-12 col-md-3">
            <h3>Productos</h3>
            <hr />
          </div>
          <div className="col-12 col-md-9">
            <div style={{ width: '100%', height: '400px' }}>
              <ScrollPanel style={{ width: '100%', height: '100%'}}>
                <div className='row'>
                  <div className='productosContainer'>
                    <div>
                      <img src="images/landing/Balasto_3.png" alt='balasto 3' /> {/* width={280} height={250} */}
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className='products title'>Material de 3”.</p>
                    <p className='products subtitle'>Estabilización de suelos, rellenos, filtros o pedraplenes. 
                    Conformación de terrenos, parques y jardines.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='productosContainer'>
                    <div>
                      <img src="images/landing/subbase3_finos.png" alt='subbase 3 a finos' /> {/* width={280} height={250} */}
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className='products title'>Material de 3” a finos.</p>
                    <p className='products subtitle'>Subbase en vialidades, relleno en estacionamientos o jardines, construcción de terraplenes.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='productosContainer'>
                    <div>
                      <img src="images/landing/Base_1_1-2.png" alt='base 1 1/2' /> {/* width={280} height={250} */}
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className='products title'>Material de 1 ½” a Finos.</p>
                    <p className='products subtitle'>Base en vialidades, construcciones de terraplenes o como material para plantillas, para recibir firmes o demás elementos de concretos.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='productosContainer'>
                    <div>
                      <img src="images/landing/Grava.png" alt='grava' /> {/* width={280} height={250} */}
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className='products title'>Material 1”.</p>
                    <p className='products subtitle'>Material para recibir y acostillar tuberías, rellenos, recibir firmes en 
                    banquetas o edificaciones pequeñas.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='productosContainer'>
                    <div>
                      <img src="images/landing/Arena.png" alt='arena 1/4 a finos' /> {/* width={280} height={250} */}
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className='products title'>Arena Reciclada (material de ¼” a finos).</p>
                    <p className='products subtitle'>Con propiedades equivalentes al tepetate (arcillas) y sin inconvenientes de los 
                    índices plásticos. Presenta valores soporte de california (CBR) superiores al 5% de tal modo que puede ser 
                    empleado en subrasantes, terraplenes, cubierta en rellenos sanitarios, andadores y ciclopostas.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="productosContainer">
                    <div>
                      <img src='images/landing/Sello.png' alt='sello 3/8' />
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className="products title">Sello de reciclado todo en uno 3&frasl;8”</p>
                    <p className="products subtitle">Ideal para uso en andadores, trota pistas, jardineras y filtros.</p>
                  </div>
                </div>
                <div className='row'>
                  <div className="productosContainer">
                    <div>
                      <img src='images/landing/CR-10.png' alt='asfalto' />
                    </div>
                    <div>
                      <img src='images/landing/CR-11.png' alt='asfalto' />
                    </div>
                    <div></div>
                  </div>
                  <div className="col-12" style={{padding: '0 20px'}}>
                    <p className='products title'>Mezcla asfáltica en frío para bacheo y banquetas</p>
                    <p className='products subtitle'>La mezcla en frío se fabrica con aglomerados de la más alta calidad y está 
                    concebida para fungir como capa superficial de pavimento. Cuando se ha aplicado el producto, presenta 
                    prácticamente las mismas características de una mezcla en caliente, sin los tediosos medios necesarios para la 
                    aplicación de esta última, preservando sus características de estabilidad, durabilidad y flexibilidad.</p>
                  </div>
                </div>
              </ScrollPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
