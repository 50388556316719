import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import React, { ReactChild, ReactFragment, ReactPortal, useState } from "react";
import EncryptService from "../../service/encrypt.service";
import ApiServices from "../../service/apiServices";

export const Convenio = () => {
    
    let emptyConvenioForm = {
        generatorName: '',
        adress: '',
        typeMaterials: '',
        volMaterials: '',
        tentativeDate: '',
        clientEmail: ''
    };

    const [convenio, setConvenio] = useState<any>(emptyConvenioForm);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [date, setDate] = useState<any>();
    const [showForm, setShowForm] = useState<boolean>(true);
    const [showResp, setShowResp] = useState<boolean>(false);

    //Funcion para editar los campos
  const onInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';

    if (name === 'tentativeDate')
    {
        setDate(e.target.value)
    }
    let _convenio = { ...convenio};
    _convenio[`${name}`] = val;
    setConvenio(_convenio);
}

const handleConvenioSubmit = async () => {
    if (convenio.generatorName && convenio.adress && convenio.typeMaterials && convenio.volMaterials && convenio.tentativeDate && convenio.clientEmail)
    {
      let palabra: any = {
        "code": 401,
        "name": convenio.generatorName,
        "location": convenio.adress,
        "typer": convenio.typeMaterials,
        "volume": convenio.volMaterials,
        "date_in": convenio.tentativeDate,
        "mail": convenio.clientEmail
      }
  
      palabra = await EncryptService.encryptData(JSON.stringify(palabra));
  
      ApiServices.processRequestAdd(palabra)
      .then(async (res) => {
        
        setShowForm(false);
        setConvenio(emptyConvenioForm);
        setDate(null)
        setSubmitted(false)
        setShowResp(true);
        
        setTimeout( () => {
            setShowForm(true)
            setShowResp(false)
        }, 6000)
          
      })
      .catch((error) => {
          //Toasty Error
          //toast.current?.show({severity: 'error', summary: 'Error', detail: error, life: 3000});
          console.log("ERROR: ", error)
      });
    }
  }

    return (
        <div id="convenio">
            <div className="container">
                <div className="col-12 col-md-6" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <h2>Convenio</h2>

                    <div className="flex justify-content-center" style={{alignSelf: 'center'}}>
                    <img className="md:mt-8" src="images/landing/convenio.png" width={'50%'} alt="convenio" style={{opacity: '0.65'}}/>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    {showForm &&  
                        <div className="col-md-12">
                        <div className="col-md-12 contactDiv">
                            <h2>Solicita</h2>
                            <p>Llena el siguiente formulario para solicitar un convenio</p>
                        </div>

                        <div className="flex flex-column col-md-12">
                            <div className="contact-item contactForm">
                                <span className="p-float-label">
                                    <InputText
                                    className={classNames({'p-invalid' : submitted && !convenio.generatorName })} 
                                    id="generatorName" 
                                    value={convenio.generatorName} 
                                    onChange={(e) => onInputChange(e, 'generatorName')}/>
                                    <label htmlFor="generatorName">Nombre</label>
                                </span>
                            </div>

                            <div className="contact-item contactForm">
                                <span className="p-float-label">
                                <InputTextarea 
                                    id="adress" 
                                    rows={3} 
                                    cols={30}
                                    className={classNames({'p-invalid' : submitted && !convenio.adress })}
                                    value={convenio.adress}
                                    onChange={(e) => onInputChange(e, 'adress')}  />
                                    <label htmlFor="adress">Dirección de la obra</label>
                                </span>
                            </div>

                            <div className="contact-item contactForm">
                                <p>Tipo de materiales a disponer y volumen que se prevé ingresar a planta.</p>
                                <span className="p-float-label">
                                    <InputText
                                    className={classNames({'p-invalid' : submitted && !convenio.typeMaterials })} 
                                    id="typeMaterials" 
                                    value={convenio.typeMaterials} 
                                    onChange={(e) => onInputChange(e, 'typeMaterials')}/>
                                    <label htmlFor="typeMaterials">Tipo de materiales</label>
                                </span>
                            </div>

                            <div className="contact-item contactForm">
                                <span className="p-float-label">
                                    <InputText
                                    className={classNames({'p-invalid' : submitted && !convenio.volMaterials })} 
                                    id="volMaterials" 
                                    value={convenio.volMaterials} 
                                    onChange={(e) => onInputChange(e, 'volMaterials')}/>
                                    <label htmlFor="volMaterials">Volumen de materiales</label>
                                </span>
                            </div>

                            <div className="contact-item contactForm">
                                <span className="p-float-label">
                                    <Calendar 
                                        value={date} 
                                        inputId="tentativeDate"
                                        className={classNames({'p-invalid' : submitted && !convenio.tentativeDate })}
                                        showIcon
                                        onChange={(e) => onInputChange(e, 'tentativeDate')}
                                        style={{width: '100%'}}></Calendar>
                                    <label htmlFor="tentativeDate">Fecha tentativa de ingreso</label>
                                </span>
                            </div>

                            <div className="contact-item contactForm">
                                <span className="p-float-label">
                                    <InputText 
                                    id="correo" 
                                    type="clientEmail"
                                    className={classNames({'p-invalid' : submitted && !convenio.clientEmail })}
                                    value={convenio.clientEmail} 
                                    onChange={(e) => onInputChange(e, 'clientEmail')}  />
                                    <label htmlFor="correo">Correo</label>
                                </span>
                            </div>

                            <Button label="ENVIAR" className="btn-lg" onClick={handleConvenioSubmit}></Button>
                        </div>
                        </div>
                        }

                        {showResp &&
                            <div className="responseContainer">
                                <h5>Mensaje enviado</h5>
                                <h6>¡Gracias por contactarnos!</h6>
                                <div className="iconContainer">
                                    <div className="icon">
                                    <svg width="88" height="90" viewBox="0 30 200 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 65.7503L8.16915 71.6712L54.616 105.343V157.243L95.0082 134.63L131.149 160.826L200 0.172852L0 65.7503ZM22.393 69.2505L172.975 19.8787L61.1755 97.372L22.393 69.2505ZM64.9228 139.663V112.812L85.8187 127.963L64.9228 139.663ZM126.756 144.916L67.4908 101.953L178.004 25.3468L126.756 144.916Z" fill="black"/>
                                    </svg>
                                    </div>
                                </div>
                                <h6>En breve nos comunicaremos contigo</h6>
                            </div>
                        }
                </div>
            </div>
        </div>
    )
}