import { Carousel } from "primereact/carousel";
import { ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";

export const Header = (props: any) => {
  const [headerInformation, setHeaderInformation] = useState<any>([]);

  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1
    }/*,
    {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 2
    }*/
  ]

  useEffect(() => {
    setHeaderInformation(props.data)
  }, []);

  const carouselTemplate = (information: any) => {
    return (
      <div className="intro" 
        style={{
          background:  `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${require(`../../imgs/${information.image}`)})`, 
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center'}}>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 intro-text">
                <h1>
                  {information ? information.title : 'Lorem'}
                  <span></span>
                </h1>
                <p>{information ? information.paragraph : 'Lorem'}</p>
                <a
                  href='/login'
                  className="btn btn-success btn-lg page-scroll">Iniciar sesión</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <header id='header' className="col-12 p-0">
      
          <Carousel
              value={headerInformation}
              numVisible={1}
              numScroll={1}
              className="custom-carousel"
              circular
              autoplayInterval={3000}
              itemTemplate={carouselTemplate}
              responsiveOptions={responsiveOptions}
              showNavigators={false}
          />
       
      
      {/*<Carousel
        value={headerInformation}
        numVisible={1}
        numScroll={2}
        className="custom-carousel"
        circular
        autoplayInterval={3000}
        itemTemplate={carouselTemplate}
        responsiveOptions={responsiveOptions}
        showNavigators={false}
      />*/}
    </header>
  )
}
