import { ReactChild, ReactFragment, ReactPortal } from "react"

export const Blog = (props: { data: any[] }) => {
  return (
    <div id='blog' className='text-center'>
      <div className='container'>
        <div className='col-12 section-title'>
          <img src="images/landing/image7.png" className="img-responsive" alt="" />
          <h1>
            Nuestra empresa cuenta con el <b>RAMIR</b><br />
            y las autorizaciones emitidas por la <br />
            <b> Secretaría del Medio Ambiente de la Ciudad de México</b>
          </h1>
        </div>
        <div className='col-12 section-title blog'>
          <h2 className="titleBlog">BLOG</h2>
        </div>

        <div className='col-12 blogInfoContainer'>
          <div className="col-12 col-md-4">
            <img src="images/landing/CR-8.png" className="img-responsive imageblog imagenaboutradius" alt="parque bicentenario" loading="lazy" />
            <h5>Proyecto parque bicentenario.</h5>
            <p className="manifest">Dentro del proyecto del parque bicentenario se reciclaron el 100% de los RCD generados, 
            generando agregado reciclado para el uso en estacionamiento, andadores, jardineras y más.</p>
          </div>
          <div className="col-12 col-md-4">
            <img src="images/landing/CR-9-1.png" className="img-responsive imageblog imagenaboutradius" alt="reciclado" loading="lazy" />
            <h5>Reciclado en sitio.</h5>
            <p className="manifest">Concretos Reciclados S.A. de C.V. ofrece el reciclaje de los RCD en sitio, lo cual reduce de manera 
            significativa los costos de la obra y a su vez genera agregados reciclados que pueden ser implementados en la obra y dar 
            cumplimiento a la legislación ambiental vigente aplicable.</p>
          </div>
          <div className="col-12 col-md-4">
            <img src="images/landing/CR-9.png" className="img-responsive imageblog imagenaboutradius" alt="sedema" loading="lazy" />
            <h5>Contamos con: </h5>
            <p className="manifest">Las autorizaciones ambientales vigente aplicables, de igual forma 
            contamos con el certificado de cumplimiento a la NACDMX-007-RNAT-2019 emitido por una Unidad de
             Verificación autorizada.</p>
          </div>
        </div>
      </div>
    </div >
  )
}
