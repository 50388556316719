import { Divider } from 'primereact/divider';
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Services = (props: { data: any[] }) => {
  const navigate = useNavigate();
  const [checkedService, setCheckedService] = useState<string[]>([])

  const onServiceChange = (e: CheckboxChangeEvent) => {
    let servicio = [...checkedService];

    if (e.checked)
      servicio.push(e.value);
    else
      servicio.splice(servicio.indexOf(e.value), 1);

    setCheckedService(servicio)
  }

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className="col-xs-12 col-md-5">
          <div className='section-title'>
            <h2>SERVICIOS</h2>
            <h3>
              Inicia tu presupuesto,<br />
              que se adapte a tus necesidades
            </h3>
          </div>
        </div>
        <div className="col-md-2 services-left">
          <Divider layout="vertical" />
        </div>
        <div className="col-xs-12 col-md-5">
          <div className='section-title'>
            <h3><b>1. Selecciona el servicio que necesitas</b></h3>
            <ul>
              <li className="service-desc">
                <Checkbox value="RCD" onChange={onServiceChange} checked={checkedService.includes('RCD')}></Checkbox>
                <p>Quiero enviar material (RCD).</p>
              </li>
              <li className="service-desc">
                <Checkbox value="comprar" onChange={onServiceChange} checked={checkedService.includes('comprar')}></Checkbox>
                <p>Quiero comprar material reciclado.</p>
              </li>
              <li className="service-desc">
                {/*<Checkbox value="reciclar" onChange={(e: any) => setChecked3(e.checked)} checked={checked3}></Checkbox>*/}
                <Checkbox value="reciclar" onChange={onServiceChange} checked={checkedService.includes('reciclar')}></Checkbox>
                <p>Quiero reciclar en sitio</p>
              </li>
              <li className="service-desc">
                <button className='btn btn-success btn-block btn-lg page-scroll' onClick={() => navigate('/login')} disabled={checkedService.length > 0 ? false : true}>Siguiente</button>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div> */}
      </div>
    </div>
  )
}
