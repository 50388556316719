import { ReactChild, ReactFragment, ReactPortal } from "react";

export const About = (props: { data: { paragraph: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; Why: any[]; Why2: any[]; }; }) => {
  return (
    <div id="about">
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <div className="col-12 col-md-6 aboutImgContainer">
          <div className="col-12" style={{display: 'flex', justifyContent: 'center'}}>
              {" "}
              <img src="images/landing/CR-3.png" className="img-responsive imagenaboutradius" alt="" loading="lazy" />
              {" "}
          </div>
          <div className="col-12 col-md-6" style={{display: 'flex', justifyContent: 'center'}}>
              {" "}
              <img src="images/landing/CR-4.png" className="img-responsive imagenabout" alt="" loading="lazy" />
              {" "}
          </div>
          <div className="col-12 col-md-6" style={{display: 'flex', justifyContent: 'center'}}>
              {" "}
              <img src="images/landing/CR-1.png" className="img-responsive imagenabout" alt="" loading="lazy" />
              {" "}
          </div>
        </div>

        <div className="col-12 col-md-6 aboutTextContainer">
          <div className="about-text">
              <h2>¿Quiénes somos?</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
